// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// MSO packages
import '@mod-mijnsiteonline/components/slideshow/slideshow';

// main CSS
import 'font-awesome/css/font-awesome.min.css';
// import 'swiper/dist/css/swiper.min.css';
import './telemetry.scss';

// pages
import './shared/pages';

// components & packages
import './widgets/widgets';
import './embeddedobjects/embeddedobjects';
// import { setupWebwerfVideos } from 'webwerf-wh-videos';
import './mobilemenu/mobilemenu';
import './smartmenus/smartmenus';

// template specific JS files
import $ from 'jquery';
window.jQuery = $;
require('swiper');
// require('./web/js/jquery.dropotron.min.js');
require('./web/js/jquery.scrollex.min.js');
require('./web/js/jquery.scrolly.min.js');
require('./web/js/jquery.selectorr.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/util.js');
require('./web/js/main');

import * as DomPack from 'dompack';

DomPack.onDomReady(() => {

  var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
  if (!touchsupport){ // browser doesn't support touch
      document.documentElement.className += " non-touch"
  }

  $('body').attr('id', 'top');

  if ($('#sidebar').length) {
    let $sections = $('#sidebar > section');
    $sections.each(function(idx, el) {
      if (idx < $sections.length - 1) {
        $(this).after('<hr />');
      }
    });
  }

  // remove empty paragraphs between elements
  let $mainElements = $('main > *');
  $mainElements.each(function(idx) {
    if (idx == 0 || idx == $mainElements.length - 1) // skip first and last
      return;

    if ($(this).get(0).tagName == 'P') {
      if ($(this).html() == '' || $(this).text() == '') {
        $(this).remove();
      }
    }
  });

  // setupWebwerfVideos();
});
