import './slideshow.scss';

import * as dompack from "dompack";

import $ from 'jquery';
import Swiper from 'swiper';

dompack.onDomReady(() => {
  if ($('.slideshow').length === 0)
    return;

  $('.slideshow').each(function () {
    let $container = $(this).find('.swiper-container').first();

    if ($(this).find('.swiper-slide').length <= 1)
      return;

    let swiper = new Swiper($container, {
      direction: 'horizontal',
      loop: true,
      speed: 500,
      autoplay: 4000,
      grabCursor: true,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 0,
    });

    if ($(this).attr('data-pauseonhover')) {
      $container.hover(function() {
        this.swiper.stopAutoplay();
      }, function() {
        this.swiper.startAutoplay();
      });
    }
  });
});
