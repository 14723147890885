import 'jquery-modal/jquery.modal.min.css';
import './verticaltabs.scss';

import * as dompack from 'dompack';

import $ from 'jquery';
import Swiper from 'swiper';
require('jquery-modal');

dompack.onDomReady(() => {
  activateTabs();

  //When window resolution is changed
  $(window).resize(function() {
    activateTabs();
  });

  function activateTabs() {
    $('.emb-verticaltabs').each(function() {
      let $embContainer = $(this);

      let isMobile = $(window).width() <= 768;

      if (isMobile) {
        $embContainer.find('a[data-modal]').click(function(evt) {
          evt.preventDefault();
          console.log('modal');
          $(this).modal();
        });
      } else {
        // Small trick to add min height to the swiper items so the bullets can render all
        var minHeight = $embContainer.find('.emb-verticaltabs__bullets').height();
        $embContainer.find('.swiper-wrapper').css('min-height', minHeight);

        let $swiperContainer = $embContainer.find('.swiper-container');

        // setup photos carousel
        var carouselSwiper = new Swiper($swiperContainer.get(0), {
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          effect: 'fade',
          fade: {
            crossFade: true
          },
          noSwipingClass: 'swiper-slide',
        });

        var $bullets = $embContainer.find('.emb-verticaltabs__bullet');

        $bullets.each(function(idx, el) {
        $(this).unbind();
          $(this).click(function(evt) {
            evt.preventDefault();
            $bullets.removeClass('selected').eq(idx).addClass('selected');
            carouselSwiper.slideTo(idx);
          });
        });
      }
    });
  }
});
